@import "../styles/vars"

.cur-pos
  display: flex
  flex-direction: column
  font-size: 12px
  line-height: 14px
  margin: 0 auto 36px
  min-width: 4em
  z-index: 1

  @media (max-width: $md)
    display: none

  .label
    &::before
      display: inline
      content: attr(data-l)
      color: $accent-color
      margin-right: .5em